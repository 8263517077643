.faq {
   padding: 60px 0;

   &-header {
      margin-bottom: 20px;

      @media only screen and (max-width: 1023px) {
         box-sizing: border-box;
      }

      &__title {
         font-size: 25px;
         color: rgb(5, 106, 180);
         font-weight: bold;
         line-height: 1.2;
      }

      &-map {
         span {
            font-size: 12px;
            color: rgb(5, 106, 180);
            line-height: 4.188;

         }
      }
   }

   &-body {
      .faqAcordion {
         margin-bottom: 30px;
      }
   }
}