.bigNewBlock {
   display: flex;
   justify-content: space-between;

   @media only screen and (max-width: 1023px) {
      flex-direction: column;
   }

   &-left {
      width: 50%;

      @media only screen and (max-width: 1023px) {
         width: 100%;
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &-right {
      width: 50%;
      padding-left: 20px;
      box-sizing: border-box;

       @media only screen and (max-width: 1023px) {
          width: 100%;
          padding: 0;
       }

      &__title {
         font-size: 22px;
         color: rgb(0, 0, 0);
         font-weight: bold;
         line-height: 1.2;
         text-align: left;
      }

      &__text {
         font-size: 21.459px;
         color: rgb(0, 0, 0);
         line-height: 1.579;
         text-align: left;
         margin-top: 20px;
         margin-bottom: 35px;
      }

      &__subtitle {
         font-size: 20px;
         color: rgb(5, 106, 180);
         font-weight: bold;
         line-height: 1.2;
         text-align: center;

      }
   }
}