.iconBlock {
   display: flex;
   align-items: center;
   flex-direction: column;
   &-img {
      width: 120px;
      height: 120px;

      img {
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
   }

   &__title {
      font-size: 19px;
      color: rgb(0, 0, 0);
      line-height: 1.944;
      text-align: center;
      margin-top: 30px;

   }
}