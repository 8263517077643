.header {
   padding: 10px 15px;
   box-sizing: border-box;
   display: flex;
   align-items: center;
   justify-content: space-between;
   min-height: 90px;
   max-height: 90px;

   @media only screen and (max-width: 1023px) {
      padding: 10px 15px;
   }


   &-left {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 768px) {

         img {
            height: 50px;
         }
      }
   }

   &-right {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1023px) {
         position: fixed;
         width: 100%;
         height: 100%;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         background: #0076BE;
         flex-direction: column;
         align-items: flex-start;
         padding-top: 35px;
         box-sizing: border-box;
         transform: translateX(-150%);
         transition: all ease 0.5s;
         z-index: 1000;
         
         &.active {
            transform: translateX(0);
            transition: all ease 0.5s;
         }
      }
      
      &-links {
         display: flex;
         
         @media only screen and (max-width: 1023px) {
            flex-direction: column;
         }
         
         &__link {
            color: #fff;
            text-decoration: none;
            padding: 0 30px;
            font-size: 13px;
            
            @media only screen and (max-width: 1023px) {
               padding: 20px;
            }
         }
      }
      
      &__select {
         background: transparent;
         border: none;
         outline: none;
         color: #fff;

         option {
            background: #fff;
            color: #0076BE;
         }
         
         @media only screen and (max-width: 1023px) {
            padding: 20px;
            
         }
      }
      
      &__view {
         background: transparent;
         outline: none;
         border: none;
         margin-left: 20px;
         
         @media only screen and (max-width: 1023px) {
            padding: 20px;
            margin-left: 0;
         }
         
         svg {
            fill: #fff;
         }
      }
      
      &__button {
         margin-left: 20px;
         background-color: rgb(255, 255, 255);
         box-shadow: 4.33px 2.5px 6.3px 0.7px rgba(0, 0, 0, 0.19);
         border-radius: 20px;
         padding: 10px 25px;
         border: none;
         outline: none;
         font-size: 12px;
         color: #0076BE;
         cursor: pointer;
         
         @media only screen and (max-width: 1023px) {
            margin-left: 20px;
         }
      }
   }
   
   .burger {
      background: #F8F8F8;
      border-radius: 10px;
      cursor: pointer;
      display: none !important;
      z-index: 1000;
      position: relative;
      
      @media only screen and (max-width: 1023px) {
         display: flex !important;
      }
      
      span {
         display: block;
         width: 25px !important;
         height: 3px;
         background: #fff;
         position: relative;
         
         &::before,
         &::after {
            content: "";
            display: block;
            width: 25px;
            height: 3px;
            background-color: #fff;
            position: absolute;
            transition: all ease 0.5s;
         }
         
         &::before {
            transform: translateY(-8px);
         }

         &::after {
            transform: translateY(8px);
         }
      }

      &.active {
         span {
            height: 0;

            &::before {
               transform: rotate(-45deg);
            }

            &::after {
               transform: rotate(47deg);
            }
         }
      }
   }
}