.footer {
   background: #E8F3F9;
   padding: 30px 0;

   @media only screen and (max-width: 768px) {
      padding: 10px 0;

   }

   &-inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media only screen and (max-width: 1023px) {
         box-sizing: border-box;


      }

      &-items {
         width: calc(100% / 4 - 50px);
         display: flex;

         @media only screen and (max-width: 768px) {
           width: auto;
         }

         .subtitle {
            margin-top: 10px;
         }

         img {
            height: 100px;
         }

         &__link {
            font-size: 15px;
            font-weight: normal;
            color: rgb(5, 106, 180);
            line-height: 1.25;
            text-decoration: none;
            margin-bottom: 10px;
            @media only screen and (max-width: 768px) {
               display: none;
            }
            
            &.max {
               width: max-content;

               @media only screen and (max-width: 1023px) {
                  width: auto;
               }
            }
         }

         flex-direction: column;


         &:nth-child(1) {
            align-items: center;
            justify-content: center;
            text-align: center;
            @media only screen and (max-width: 768px) {
               display: none;
            }
         }

         &:nth-child(2) {
            align-items: center;
            justify-content: center;
            text-align: center;
            @media only screen and (max-width: 768px) {
               height: 70px;
               
               img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;

               }
            }
         }

         &-box {
            display: flex;
            align-items: center;

            &__link {
               width: 24px;
               height: 24px;
               margin-right: 10px;

               svg {
                  width: 100%;
                  height: 100%;
               }
            }
         }
      }
   }
}