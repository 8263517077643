.faqAcordion {
   cursor: pointer;
   user-select: none;

   &-top {
      background: #F2F2F2;
      padding: 10px 0;

      &-inner {
         position: relative;
         display: flex;
         align-items: center;

         @media only screen and (max-width: 1023px) {
            box-sizing: border-box;
         }

         &__icon {}

         &__title {
            margin-left: 20px;
            font-size: 20.33px;
            font-weight: 500;
            color: rgb(0, 0, 0);
            line-height: 1.476;
         }


         &__arrow {
            margin-left: auto;
         }
      }
   }

   &-center {
      padding-left: 63px;
      padding-top: 20px;
      box-sizing: border-box;

      &__text {
         font-size: 19px;
         color: rgb(0, 0, 0);
         line-height: 1.563;

      }
   }
}