@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
   margin: 0;
   padding: 0;
   font-family: 'Roboto',
      sans-serif;
   overflow-y: auto !important;
   padding-right: 0 !important;

   .home-header__button {
      border: none;
      outline: none;
      padding: 8px 18px;
      font-weight: 500;
      color: #fff;
      background: #0075be88;
      border-radius: 5px;
      cursor: pointer;
   }

   // color vision black
   &.n3__wcag__color_black {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li,
      button,
      a,
      span {
         color: #fff !important;
      }

      div {
         background-color: #000 !important;
      }

      .n3__vision__settings-button_selected {
         color: #000 !important;

      }

      .n3__vision__color-button_selected {
         color: #000 !important;
      }

      div.home-section1 {
         background-color: none !important;


      }

      div.home-section1-left {
         background: none !important;
      }


      div.home-section1-right {
         background-color: #000000a9 !important;
      }

      div.tableBlock-box-top {
         background-color: #fff !important;
      }

      button.tableBlock-inner__button {
         svg {
            fill: #0076BE !important;
         }
      }

      button.header-right__button {
         background: #0076BE !important;
      }

      div.footer {
         border-top: 1px solid #fff;
      }
   }


   // color vision white
   &.n3__wcag__color_white {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li,
      a,
      span {
         color: #000 !important;
      }

      div.footer {
         border-top: 1px solid #000;
      }

      button {
         background: #000;
      }


      div {
         background-color: #fff !important;
      }

      .n3__vision__settings-button_selected {
         color: #000 !important;
         border: 1px solid #000 !important;

      }

      .n3__vision__color-button_selected {
         color: #000 !important;
         border: 1px solid #000 !important;
      }

      div.home-section1 {
         background-color: none !important;


      }

      div.home-section1-left {
         background: none !important;
      }


      div.home-section1-right {
         background-color: #ffffffa9 !important;
      }

      div.tableBlock-box-top {
         background-color: #000 !important;
      }

      .myTable-footer button {
         background: transparent !important;

         &.btnActive {
            background: #000 !important;
            color: #fff !important;
         }
      }

      button.tableBlock-inner__button {
         background: transparent !important;

         svg {
            fill: #0076BE !important;
         }
      }

      div.header-left {
         background: #0076BE !important;
      }

      button.header-right__button {
         color: #fff !important;
      }

      .n3__vision__settings-group__label {
         color: #000 !important;
      }
   }

   // font size large 
   &.n3__wcag__fontsize_large {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li,
      button,
      a,
      span {
         font-size: 25px;
      }

      button.n3__vision__settings-button {
         font-size: 13px !important;
      }

      .n3__vision__settings-group__label {
         font-size: 15px !important;
      }

      .n3__vision__settings-panel__buttons-right button.n3__vision__action-button {
         font-size: 0 !important;
      }

      .n3__vision__action-button {
         font-size: 13px !important;
      }

      .n3__vision__color-button {
         font-size: 15px !important;
      }
   }

   // font  kering  small
   &.n3__wcag__kerning_small {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li,
      button,
      a,
      span {
         letter-spacing: -1px;
      }

      button.n3__vision__settings-button {
         letter-spacing: 0 !important;
      }

      .n3__vision__settings-group__label {
         letter-spacing: 0 !important;
      }

      .n3__vision__settings-panel__buttons-right button.n3__vision__action-button {
         letter-spacing: 0 !important;
      }

      .n3__vision__action-button {
         letter-spacing: 0 !important;
      }

      .n3__vision__color-button {
         letter-spacing: 0 !important;
      }
   }

   // font kerring large
   &.n3__wcag__kerning_large {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li,
      button,
      a,
      span {
         letter-spacing: 3px;
      }

      button.n3__vision__settings-button {
         letter-spacing: 0 !important;
      }

      .n3__vision__settings-group__label {
         letter-spacing: 0 !important;
      }

      .n3__vision__settings-panel__buttons-right button.n3__vision__action-button {
         letter-spacing: 0 !important;
      }

      .n3__vision__action-button {
         letter-spacing: 0 !important;
      }

      .n3__vision__color-button {
         letter-spacing: 0 !important;
      }
   }

   &.n3__wcag__fontsize_small {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li,
      button,
      a,
      span {
         font-size: 13px;
      }

      button.n3__vision__settings-button {
         font-size: 13px !important;
      }

      .n3__vision__settings-group__label {
         font-size: 15px !important;
      }

      .n3__vision__settings-panel__buttons-right button.n3__vision__action-button {
         font-size: 0 !important;
      }

      .n3__vision__action-button {
         font-size: 13px !important;
      }

      .n3__vision__color-button {
         font-size: 15px !important;
      }
   }

   &.n3__wcag__images_off {
      .imgShow {
         opacity: 0;
      }

      .home-section1 {
         background: none !important;
      }
   }



}

h1,
h2,
h3,
h4,
h5,
h6,
p {
   margin: 0;
   padding: 0;
}


.container {
   max-width: 1140px;
   width: 100%;
   margin: 0 auto;
   padding: 0 15px;
   box-sizing: border-box;

   @media only screen and (max-width: 1023px) {
      max-width: 1023px;
   }

   @media only screen and (max-width: 768px) {
      max-width: 768px;
   }
}

.app {
   &-header {
      background: #0076BE;
   }

   &-body {
      min-height: calc(100vh - 265px);
   }


}


.n3__vision__modal {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   max-height: 100px;
   min-height: 100px;
   background: #0076BE;
   padding: 10px 0;
   box-sizing: border-box;
   z-index: 3000;

   @media only screen and (max-width: 1023px) {
      bottom: 0;
      min-height: 100vh;
      max-height: 100vh;
      padding: 10px 20px;
      width: 100%;
   }

   @media only screen and (max-width: 768px) {
      .n3__vision__settings-panel__groups {
         flex-direction: column;
      }

      .n3__vision__settings-group {
         margin-bottom: 20px;
      }
   }
}

.n3__vision__settings-panel__groups {
   display: flex;
   align-items: center;
   justify-content: space-around;

}

.n3__vision__settings-group__label {
   color: #fff;
   margin-bottom: 5px;
}

.n3__vision__settings-panel__buttons {
   display: flex;
   align-items: center;
   margin-top: 10px;
   justify-content: flex-end;
   padding-right: 20px;
}

.n3__vision__settings-panel__buttons-right {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   margin: 0 5px;

   button {
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
      align-content: center;
      justify-content: center;
      border: 1px solid #fff;
      background: transparent;
      outline: none;
      padding: 3px;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 3px;
      font-size: 0;
      overflow: hidden;
      position: relative;

      &::before {
         content: "";
         position: absolute;
         width: 10px;
         height: 2px;
         background: #fff;
         transform: rotate(-45deg);
         display: flex;
         align-content: center;
         justify-content: center;
      }

      &::after {
         content: "";
         position: absolute;
         width: 10px;
         height: 2px;
         background: #fff;
         transform: rotate(45deg);
         display: flex;
         align-content: center;
         justify-content: center;
      }
   }

}

.n3__vision__action-button {
   color: #fff;
   background: transparent;
   border: 1px solid #fff;
   outline: none;
   margin: 0 5px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   padding: 3px 5px;
   box-sizing: border-box;
   cursor: pointer;
   border-radius: 3px;
}

.n3__vision__settings-button {
   color: #fff;
   background: transparent;
   border: 1px solid #fff;
   outline: none;
   margin: 0 5px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   padding: 3px 5px;
   box-sizing: border-box;
   cursor: pointer;
   border-radius: 3px;

   &.n3__vision__settings-button_selected {
      background: #fff;
      color: #0076BE;

   }
}

.n3__vision__color-button {
   color: #fff;
   background: transparent;
   border: 1px solid #fff;
   outline: none;
   margin: 0 5px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   padding: 3px 5px;
   box-sizing: border-box;
   cursor: pointer;
   border-radius: 3px;

   &.n3__vision__color-button_selected {
      background: #fff;
      color: #0076BE;
   }
}