.schoolCreteries {
   padding: 60px 0;

   &-header {
      margin-bottom: 20px;

      &__title {
         font-size: 25px;
         color: rgb(5, 106, 180);
         font-weight: bold;
         line-height: 1.2;
      }

      &-map {
         span {
            font-size: 16px;
            color: rgb(5, 106, 180);
            line-height: 4.188;

         }
      }
   }

   &-body {
      .faqAcordion {
         margin-bottom: 30px;
      }
   }
}