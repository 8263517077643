.news-body {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   .newBlock {
      width: calc(100% / 3 - 40px);
      margin-bottom: 30px;
      
   }

   @media only screen and (max-width: 1023px) {
      min-width: 768px;

      .newBlock {
         margin-right: 20px;
         width: 100%;
      }
   }
}