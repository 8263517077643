.newBlock {
   width: 100%;
   cursor: pointer;

   &-img {
      width: 100%;
      min-height: 220px;
      max-height: 220px;
      
      img {
         max-width: 330px;
         min-width: 330px;
         min-height: 220px;
         max-height: 220px;
         object-fit: cover;
      }
   }

   &-info {
      margin-top: 20px;
      &__title {
         font-size: 13px;
         color: rgb(0, 0, 0);
         font-weight: bold;
         line-height: 1.2;
         text-align: left;
         
      }
      
      &__text {
         margin-top: 10px;
         font-size: 11px;
         color: rgb(0, 0, 0);
         line-height: 1.579;
         text-align: left;
         margin-bottom: 10px;
         
      }
      
      &__subtitle {
         font-size: 13px;
         color: rgb(5, 106, 180);
         font-weight: bold;
         line-height: 1.2;

      }
   }
}