.home {
   .cls-1 {
      fill: #fff;
   }

   &-section1 {
      display: flex;
      width: 100%;
      min-height: 465px;
      max-height: 465px;
      background-image: url(../../assets/Images/back1.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &:hover {


         .home-section1-right {
            // transition: 0.5s;
            width: 100%;

           &__title {
              padding: 0;
           }
         }

         .home-section1-left {
            // transition: 0.5s;
            width: 0;
         }
      }

      &-left {
         width: 50%;
         // transition: 0.5s;

         @media only screen and (max-width: 1023px) {
            width: 0;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      &-right {

         @media only screen and (max-width: 1023px) {
            width: 100%;

            br {
               display: none;
            }
         }

         // transition: 0.5s;
         width: 50%;
         background: #ffffffa9;
         background-repeat: no-repeat;
         background-position: center;
         display: flex;
         align-items: center;
         justify-content: center;
         flex-direction: column;

         &__title {
            max-width: 1140px;
            font-size: 27px;
            color: rgb(5, 106, 180);
            text-align: center;
            text-shadow: 4.33px 2.5px 6.3px rgba(0, 0, 0, 0.1);
            padding: 0 20%;
            @media only screen and (max-width: 1023px) {
               max-width: 1023px;
            }
            @media only screen and (max-width: 768px) {
               padding: 0;
            }
         }

         &__button {
            margin-top: 70px;
            border-radius: 16px;
            background-color: rgb(5, 106, 180);
            box-shadow: 4.33px 2.5px 6.3px 0.7px rgba(0, 0, 0, 0.19);
            color: #fff;
            border: none;
            outline: none;
            padding: 10px 25px;
            cursor: pointer;
         }
      }
   }

   &-section2 {
      margin-top: 50px;

      @media only screen and (max-width: 1023px) {
         overflow-x: scroll;
         width: 100%;
         box-sizing: border-box;
      }

      .home-scroll {
         box-sizing: border-box;
         min-width: 1140px;
      }

      &-top {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 30px;

      }
   }

   &-section3 {
      background: #F1F8FB;
      margin-top: 42px;
      padding: 42px 0;


      &-inner {
         display: flex;
         align-items: center;
         flex-direction: column;

         @media only screen and (max-width: 1023px) {
            box-sizing: border-box;
         }

         &__title {
            font-size: 30px;
            color: rgb(5, 106, 180);
            font-weight: bold;
            text-decoration: underline;
            line-height: 1.2;
         }

         &-row {
            display: flex;
            justify-content: space-around;
            margin-top: 50px;

            @media only screen and (max-width: 1023px) {
               justify-content: space-between;
            }

            @media only screen and (max-width: 768px) {
               flex-direction: column;
               align-items: center;
            }

            .iconBlock {
               width: calc(100% / 3 - 30px);

               @media only screen and (max-width: 768px) {
                  width: 50%;
                  margin-bottom: 40px;
               }

               @media only screen and (max-width: 500px) {
                  width: 100%;
               }
            }
         }
      }
   }

   &-section4 {
      padding: 30px 0;
      box-sizing: border-box;


      &__title {
         font-size: 30px;
         color: rgb(5, 106, 180);
         font-weight: bold;
         text-decoration: underline;
         line-height: 1.2;
         text-align: center;
      }

      &-inner {
         display: flex;
         margin-top: 30px;

         @media only screen and (max-width: 1023px) {
            flex-direction: column-reverse;
         }

         &-left {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            padding: 0 30px;

            @media only screen and (max-width: 1023px) {
               width: 100%;
               padding: 0 15px;
               box-sizing: border-box;
            }

            &__title {
               font-size: 20px;
               color: rgb(0, 0, 0);
               line-height: 1.65;
               text-align: left;
               width: 70%;

               @media only screen and (max-width: 1023px) {
                  width: 100%;
               }

            }

         }

         &-right {
            width: 50%;

            @media only screen and (max-width: 1023px) {
               width: 100%;
            }

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }
      }
   }

   &-section5 {
      padding: 30px 0;
      box-sizing: border-box;
      background: #F1F8FB;





      &__title {
         font-size: 30px;
         color: rgb(5, 106, 180);
         font-weight: bold;
         text-decoration: underline;
         line-height: 1.2;
         text-align: center;
      }


      &-inner {
         margin-top: 30px;

         @media only screen and (max-width: 1023px) {
            box-sizing: border-box;
         }

         .home-section5-scroll {
            display: flex;
            justify-content: space-between;
            @media only screen and (max-width: 1023px) {
               min-width: 768px;

               .newBlock {
                  margin-right: 20px;
                  width: 100%;
               }
            }
            
         }

         &-center {
            margin-top: 30px;

            @media only screen and (max-width: 1023px) {
               overflow-x: scroll;
            }
            .newBlock {
               width: calc(100% / 3 - 40px);
            }
         }

         &-footer {
            display: flex;
            align-items: center;
            justify-content: center;

            &__button {
               margin-top: 70px;
               border-radius: 16px;
               background-color: rgb(5, 106, 180);
               box-shadow: 4.33px 2.5px 6.3px 0.7px rgba(0, 0, 0, 0.19);
               color: #fff;
               border: none;
               outline: none;
               padding: 10px 25px;
               cursor: pointer;
            }
         }
      }
   }

   &-section6 {
      margin-top: 42px;


      &__title {
         font-size: 30px;
         color: rgb(5, 106, 180);
         font-weight: bold;
         text-decoration: underline;
         line-height: 1.2;
         text-align: center;
      }



   }
}