.reytingTable {
   margin-top: 30px;
   border-radius: 30px;
   width: 100%;
   overflow: hidden;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

   &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 900px) {
         overflow-x: scroll;
      }

      &__button {
         padding: 20px 10px;
         width: calc(100% / 5);
         background: #006AB8;
         color: #fff;
         border: none;
         outline: none;
         border-right: 2px solid #fff;
         cursor: pointer;
         font-size: 14px;
         line-height: 1.2;
         text-align: center;
         font-weight: bold;

         @media only screen and (max-width: 900px) {
            width: max-content;
         }

         &:last-child {
            border-right: none;
         }

         &.active {
            background: #fff;
            color: #006AB8;
            box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
               rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
         }
      }
   }

   &-body {

      width: 100%;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 900px) {
         overflow-x: scroll;
      }

      &__button {
         padding: 20px 10px;
         width: calc(100% / 5);
         border: none;
         outline: none;
         border-right: 2px solid #000;
         cursor: pointer;
         font-size: 14px;
         line-height: 1.2;
         text-align: start;
         font-weight: bold;
         box-sizing: border-box;
         h4 {
            margin-bottom: 30px;

            b {
               margin-right: 10px;
            }
         }

         @media only screen and (max-width: 900px) {
            width: max-content;
         }

         &:last-child {
            border-right: none;
         }

         &.active {
            background: #fff;
            color: #006AB8;
            box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
               rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
         }
      }
   }
}