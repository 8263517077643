.searchInput  {
   border-bottom: 2px solid rgb(186, 186, 186);
   width: 300px;
   display: flex;
   align-items: center;
   padding-bottom: 5px;
   img {
      margin-right: 10px;
   }

   input {
      border: none;
      outline: none;
      width: 100%;
      font-size: 18px;
      font-family: 'Maven Pro',
      sans-serif;
   }
}