.schoolsReyting {
   padding: 60px 0;

   &-header {
      margin-bottom: 20px;

      &__title {
         font-size: 25px;
         color: rgb(5, 106, 180);
         font-weight: bold;
         line-height: 1.2;
      }

      &-map {
         span {
            font-size: 16px;
            color: rgb(5, 106, 180);
            line-height: 4.188;

         }
      }
   }

   &-body {

      &__title {
         font-size: 30px;
         color: black;
         font-weight: bold;
         text-decoration: underline;
         line-height: 1.2;
         text-align: center;
      }

      &-top {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-top: 30px;

         @media only screen and (max-width: 900px) {
            flex-wrap: wrap;

            .selectInput {
               margin-bottom: 20px;
            }
         }

         button {
            background: #E8F3F9;
            color: #007EBE;
            padding: 10px 20px;
            border: none;
            outline: none;
            border-radius: 5px;
            cursor: pointer;
         }
      }
   }
}