.contact-inner {
   margin-top: 42px;
   display: flex;
   justify-content: space-between;

    @media only screen and (max-width: 1023px) {
      justify-content: none;
      flex-direction: column-reverse;
      box-sizing: border-box;

      iframe {
         width: 100%;
      }
    }

   &-left {
      margin-right: 20px;

      @media only screen and (max-width: 1023px) {
         margin: 0;
         margin-top: 30px;
         display: flex;
         flex-wrap: wrap;
      }
      &-row {
         display: flex;
         align-items: flex-start;
         margin-bottom: 30px;

          @media only screen and (max-width: 1023px) {
             margin-right: 20px;
          }


         &-icon {
            background: #006AB8;
            padding: 4px;
            box-sizing: border-box;
            border-radius: 50%;
            min-width: 24px;
            max-width: 24px;
            min-height: 24px;
            max-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
               width: 15px;
               height: 15px;
               fill: #fff;
            }
         }

         &-info {
            display: flex;
            flex-direction: column;
            margin-left: 17px;


            &__title {
               font-size: 19px;
               font-weight: normal;
               color: rgb(5, 106, 180);
               line-height: 1.25;
            }

            &__subtitle {
               font-size: 19px;
               font-weight: 500;
               color: black;
               line-height: 1.25;
               text-decoration: none;
            }

            .social {
               margin-top: 30px;
            }

            &-box {
               margin-top: 20px;
               display: flex;

               a {
                  text-decoration: none;
                  width: 24px;
                  height: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 20px;

                  svg {
                     width: 100%;
                     height: 100%;
                  }
               }
            }

         }
      }
   }
}