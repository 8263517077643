.myTable {
   border-width: 1px;
   border-color: rgb(185, 225, 255);
   border-style: solid;

   &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgb(247, 249, 254);
      padding: 33px 40px;
      box-sizing: border-box;

      &__title {
         &:nth-child(1) {
            min-width: 50%;
         }

         // &:nth-child(2) {
         //    min-width: 20%;

         // }

         &:nth-child(2) {
            width: 60px;
            text-align: center;
         }

         &:nth-child(3) {
            width: 60px;
            text-align: center;
         }

         &:nth-child(4) {
            width: 30px;
         }
      }
   }


   &-footer {
      display: flex;
      align-items: center;
      padding: 20px 0;
      
      &-inner {
         display: flex;
         align-items: center;
      }

      button {
         background: transparent;
         border: none;
         outline: none;
         margin-right: 10px;
         font-size: 14px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 50%;
         min-height: 20px;
         min-width: 20px;
         cursor: pointer;

         .btnActive {
            background: rgb(5, 106, 180);
            color: #fff;
         }
      }
   }


}