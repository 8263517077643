.selectInput {
   border: none;
   outline: none;
   background: transparent;
   width: 160px;
   border-bottom: 1px solid #0076BE;
   font-size: 16px;
   color: rgb(82, 82, 82);
   line-height: 1.2;
   position: relative;

   
   
  

}