.tableBlock {
   display: flex;
   flex-direction: column;
   box-sizing: border-box;
   position: relative;
   padding-bottom: 33px;
   
   &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 33px 40px 0 40px;

      &__title {
         font-size: 18px;
         color: rgb(0, 0, 0);
         line-height: 1.2;

         &:nth-child(1) {
            min-width: 50%;
            max-width: 50%;
            display: flex;
            flex-wrap: wrap;
         }

         // &:nth-child(2) {
         //    min-width: 20%;
         //    max-width: 20%;

         // }

         &:nth-child(2) {
            width: 60px;
            text-align: center;
         }

         &:nth-child(3) {
            width: 60px;
            text-align: center;

            &.red {
               color: #FF0404;
            }
            
            &.orange {
               color: orange;

            }

            &.green {
               color: #00C146;
            }
            &.blue {
               color: rgb(5, 106, 180);
            }
         }



      }



      &__button {
         border: none;
         outline: none;
         background: transparent;
         cursor: pointer;
         &:hover {
            svg {
               opacity: 1;
            }
         }



         svg {
            transform: rotate(-90deg);
            opacity: 0.3;
         }
         
         &.active {
            svg {
               opacity: 1;
               transform: rotate(90deg);
            }
         }
      }
   }

   &-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      overflow: hidden;
      border: 2px solid #F6F9FE;
      transform-origin: top;
      position: absolute;
      transform: scaleY(0);
      box-sizing: border-box;
      &.active {
         transform: scaleY(1);
         position: relative;
      }

      &-top {
         display: flex;
         align-items: center;
         justify-content: space-around;
         padding: 20px 0;
         background: #F6F9FE;
         
         &__icons {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
      
      &-center {
         display: flex;
         align-items: center;
         justify-content: space-around;
         padding: 20px 0;
         
         &__icons {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;

            &.red {
               color: #FF0404;
            }

            &.orange {
               color: orange;

            }

            &.green {
               color: #00C146;
            }
            &.blue {
               color: rgb(5, 106, 180);
            }

          }
      }
   }


}